<template>
  <div class="dissolution">
    <div class="icon-wrap">
      <i class="iconfont el-icon-warning-outline"></i>
    </div>
    <p class="desc">
      解散后，你的后台管理帐号和通讯录将会删除；（如有需要，请先
      <span @click="handleExport">导出通讯录</span>
      )
    </p>
    <div class="btn-wrap">
      <el-button type="danger" @click="onDissolutionClick">解散</el-button>
    </div>
  </div>
  <el-dialog
    v-model="state.showConfirm"
    custom-class="custom-dialog"
    title="短信验证"
    width="472px"
    :append-to-body="true"
  >
    <div class="tip">
      <span class="iconfont el-icon-warning"></span>
      为了确保信息安全，请输入手机验证码
    </div>
    <div class="form-wrap">
      <el-form ref="verifyFormRef" :rules="verifyRules" label-width="108px" :model="verifyForm">
        <el-form-item label="绑定手机号码" prop="mobile">
          <!-- <template #prepend>+86</template> -->
          <el-input
            size="small"
            :disabled="true"
            :model-value="`+86-${mobileNumberChange(verifyForm.mobile)}`"
          ></el-input>
          <el-button class="btn-get-code" type="text" @click="getCode">
            {{ time === 60 ? '获取验证码' : `${time}秒后重发` }}
          </el-button>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input size="small" v-number maxlength="6" v-model="verifyForm.code"></el-input>
        </el-form-item>
        <div class="footer-buttons">
          <el-button type="primary" :disabled="!verifyForm.code" size="small" @click="onSubmit">确认</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
import { onUnmounted, reactive, ref, watch } from 'vue'
import { useLogout, useVerifyCode } from '@/hooks/sys'
import { mobileNumberChange } from '@/common/utils/util'
import { useUser } from '@/hooks'
import { ElMessage } from 'element-plus'
import { deleteCompany, exportTemplate, sendSms } from '@/apis'
import { useRouter } from 'vue-router'
export default {
  name: 'dissolution',
  setup() {
    const { user } = useUser()
    const router = useRouter()
    const state = reactive({
      showConfirm: false
    })
    const verifyForm = reactive({
      mobile: user.value.mobile,
      code: ''
    })
    const stop = watch(
      () => user.value.mobile,
      val => (verifyForm.mobile = val),
      { immediate: true }
    )
    /**
     * 获取手机验证码
     */
    const getVerifyCode = () => {
      if (!verifyForm.mobile) {
        ElMessage.error('手机号码不能为空')
        return Promise.reject(new Error('mobile is required'))
      }
      return sendSms(verifyForm.mobile)
    }
    const { time, getCode } = useVerifyCode(getVerifyCode)

    const verifyRules = {
      mobile: [{ required: true, message: '请输入手机号码' }]
    }
    const verifyFormRef = ref(null)
    const onDissolutionClick = () => {
      state.showConfirm = true
    }
    const onSubmit = () => {
      verifyFormRef.value.validate(async valid => {
        if (valid) {
          try {
            const result = await deleteCompany(verifyForm.code)
            if (result) {
              const doLogout = useLogout(false)
              await doLogout()
              ElMessage.success('解散成功')
              router.replace('/login')
            }
          } catch (error) {
            ElMessage.error(error.message)
          }
        }
      })
    }
    const handleExport = async () => {
      try {
        await exportTemplate(false)
        ElMessage.success('导出成功')
      } catch (error) {
        ElMessage.error('导出失败')
      }
    }
    onUnmounted(() => typeof stop === 'function' && stop())
    return {
      state,
      verifyFormRef,
      verifyForm,
      verifyRules,
      time,
      onDissolutionClick,
      onSubmit,
      getCode,
      mobileNumberChange,
      handleExport
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
